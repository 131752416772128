<template>
  <div class="d-flex align-items-center flex-wrap mr-1">
    <div
      id="kt_header_menu"
      ref="kt_header_menu"
      class="header-menu header-menu-mobile"
    >
      <ul class="menu-nav">
        <!-- begin::Oversikt-->
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="`/templates/edit/${ templateId }`"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ trans('templates.edit.btns.dashboard') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end::Oversikt-->

        <!-- begin::Styletypes-->
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="`/templates/edit/${ templateId }/styletypes`"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ trans('templates.edit.btns.styletypes') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end::Styletypes-->

        <!-- begin::Designer-->
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="`/templates/edit/${ templateId }/designer`"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ trans('templates.edit.btns.designer') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end::Designer-->

        <!-- begin::Settings-->
        <router-link
          v-slot="{ href, navigate, isExactActive }"
          :to="`/templates/edit/${ templateId }/settings`"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ trans('templates.edit.btns.settings') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end::Settings-->
      </ul>
    </div>
  </div>
</template>

<script>
  import { computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import * as objectPath from 'object-path'

  export default {
    name: 'SubHeaderTemplatesEdit',
    props: {
      subdata: {
        type: Object,
        default: () => ({})
      }
    },
    setup (props) {
      const templateId = computed(() => objectPath.get(props, 'subdata.template.id'), null)

      return {
        templateId,
        trans,
        transChoice
      }
    }
  }
</script>
